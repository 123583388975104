<template>
  <div class="container my-4">
    <div class="d-flex flex-column justify-content-center align-items-center mx-4">
      <span class="text-uppercase">General Instructions</span>
      <b-form-textarea
        v-model="generalInstructions"
        class="my-2"
        rows="4"
      />
      <b-button
        variant="primary"
        class="w-100"
        :disabled="!generalInstructions"
        @click="handleAddGeneralInstructions"
      >
        Add Instructions
      </b-button>
    </div>
  </div>
</template>
<script>
import store from '@/store'
import { ref, watch } from '@vue/composition-api'
import {
  BFormTextarea,
  BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BFormTextarea,
    BButton,
  },
  emits: ['changeGeneralInstructions'],
  setup(props, context) {
    const generalInstructions = ref('')

    watch([generalInstructions], () => {
      context.emit('changeGeneralInstructions', generalInstructions.value)
    })

    const handleAddGeneralInstructions = () => {
      store.commit('cart/ADD_GENERAL_INSTRUCTIONS', generalInstructions.value)
      context.root.$emit('bv::toggle::collapse', 'general-instructions')
    }

    return {
      generalInstructions,
      handleAddGeneralInstructions,
    }
  },
}
</script>
