var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container my-2"},[_c('validation-observer',{ref:"customerRules"},[_c('b-form',[_c('h3',[_vm._v("Basic Info")]),_c('b-row',{staticClass:"container"},[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label-cols-md":"12"}},[_c('label',{attrs:{"for":"first-name"}},[_vm._v(" First Name "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"First Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"first-name","placeholder":"First Name","state":errors.length > 0 ? false:null},model:{value:(_vm.firstName),callback:function ($$v) {_vm.firstName=$$v},expression:"firstName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label-cols-md":"12"}},[_c('label',{attrs:{"for":"last-name"}},[_vm._v(" Last Name "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"Last Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"last-name","placeholder":"Last Name","state":errors.length > 0 ? false:null},model:{value:(_vm.lastName),callback:function ($$v) {_vm.lastName=$$v},expression:"lastName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label-cols-md":"12"}},[_c('label',{attrs:{"for":"email"}},[_vm._v(" Email ")]),_c('b-form-input',{attrs:{"id":"email","placeholder":"Email"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label-cols-md":"12"}},[_c('label',{attrs:{"for":"phone"}},[_vm._v(" Phone Number "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"Phone Number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"id":"phone","placeholder":"Phone Number","state":errors.length > 0 ? false:null},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',{staticClass:"container my-2"},[_c('h3',[_vm._v("Address")]),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label-cols-md":"12"}},[_c('label',{attrs:{"for":"address-name"}},[_vm._v(" Name "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"Address Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"id":"address-name","placeholder":"Name","state":errors.length > 0 ? false:null},model:{value:(_vm.addressName),callback:function ($$v) {_vm.addressName=$$v},expression:"addressName"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label-cols-md":"12"}},[_c('label',{attrs:{"for":"city"}},[_vm._v(" City "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"City","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"w-100",attrs:{"id":"city","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.cities,"label":"name","clearable":false,"placeholder":"Select City","reduce":function (ci) { return ci.id; },"state":errors.length > 0 ? false:null},on:{"input":_vm.handleCityChange},model:{value:(_vm.city),callback:function ($$v) {_vm.city=$$v},expression:"city"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label-cols-md":"12"}},[_c('label',{attrs:{"for":"area"}},[_vm._v(" Area "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"Area","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"w-100",attrs:{"id":"area","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.areas,"label":"name","clearable":false,"placeholder":"Select Area","reduce":function (ar) { return ar.id; },"state":errors.length > 0 ? false:null},model:{value:(_vm.area),callback:function ($$v) {_vm.area=$$v},expression:"area"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label-cols-md":"12"}},[_c('label',{attrs:{"for":"block"}},[_vm._v(" Block "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"Block","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"block","placeholder":"Block"},model:{value:(_vm.block),callback:function ($$v) {_vm.block=$$v},expression:"block"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label-cols-md":"12"}},[_c('label',{attrs:{"for":"avenue"}},[_vm._v(" Avenue ")]),_c('b-form-input',{attrs:{"id":"avenue","placeholder":"Avenue"},model:{value:(_vm.avenue),callback:function ($$v) {_vm.avenue=$$v},expression:"avenue"}})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label-cols-md":"12"}},[_c('label',{attrs:{"for":"street"}},[_vm._v(" Street "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"Street","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"id":"street","placeholder":"Street","state":errors.length > 0 ? false:null},model:{value:(_vm.street),callback:function ($$v) {_vm.street=$$v},expression:"street"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label-cols-md":"12"}},[_c('label',{attrs:{"for":"building"}},[_vm._v(" Building/House "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"Building/House","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"id":"building","placeholder":"Building/House","state":errors.length > 0 ? false:null},model:{value:(_vm.building),callback:function ($$v) {_vm.building=$$v},expression:"building"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label-cols-md":"12"}},[_c('label',{attrs:{"for":"floor"}},[_vm._v(" Floor ")]),_c('b-form-input',{attrs:{"id":"floor","placeholder":"Floor"},model:{value:(_vm.floor),callback:function ($$v) {_vm.floor=$$v},expression:"floor"}})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label-cols-md":"12"}},[_c('label',{attrs:{"for":"apartment"}},[_vm._v(" Apartment ")]),_c('b-form-input',{attrs:{"id":"apartment","placeholder":"Apartment"},model:{value:(_vm.apartment),callback:function ($$v) {_vm.apartment=$$v},expression:"apartment"}})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label-cols-md":"12"}},[_c('label',{attrs:{"for":"landmark"}},[_vm._v(" Landmark ")]),_c('b-form-input',{attrs:{"id":"landmark","placeholder":"Landmark"},model:{value:(_vm.landmark),callback:function ($$v) {_vm.landmark=$$v},expression:"landmark"}})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label-cols-md":"12"}},[_c('label',{attrs:{"for":"details"}},[_vm._v(" Details ")]),_c('b-form-input',{attrs:{"id":"details","placeholder":"Details"},model:{value:(_vm.details),callback:function ($$v) {_vm.details=$$v},expression:"details"}})],1)],1)],1),_c('hr'),_c('h3',[_vm._v("Special Discount")]),_c('b-row',{staticClass:"container"},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label-cols-md":"12"}},[_c('label',{attrs:{"for":"discount"}},[_vm._v(" Discount ")]),_c('validation-provider',{attrs:{"name":"Discount","rules":"between:0,100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"id":"discount","placeholder":"Enter Percentage","state":errors.length > 0 ? false:null},model:{value:(_vm.discount),callback:function ($$v) {_vm.discount=$$v},expression:"discount"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.error)+" ")])]),_c('b-col',{attrs:{"md":"12"}},[_c('b-button',{staticClass:"mr-1 w-100 my-2",attrs:{"type":"submit","variant":"success","disabled":_vm.submitButtonDisabled},on:{"click":function($event){$event.preventDefault();return _vm.handleSaveAndCreateNewOrder($event)}}},[_vm._v(" "+_vm._s(_vm.submitButtonDisabled ? 'Loading...' : 'Save And Create New Order')+" ")])],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-button',{staticClass:"mr-1 w-100 my-2",attrs:{"type":"submit","variant":"primary","disabled":_vm.submitButtonDisabled},on:{"click":function($event){$event.preventDefault();return _vm.handleSaveOnly($event)}}},[_vm._v(" "+_vm._s(_vm.submitButtonDisabled ? 'Loading...' : 'Save Only')+" ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }