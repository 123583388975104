<template>
  <div class="container my-2">
    <validation-observer ref="customerRules">
      <b-form>
        <h3>Basic Info</h3>
        <b-row class="container">
          <b-col cols="6">
            <b-form-group
              label-cols-md="12"
            >
              <label
                for="first-name"
              >
                First Name <span class="text-danger">*</span>
              </label>
              <validation-provider
                #default="{ errors }"
                name="First Name"
                rules="required"
              >
                <b-form-input
                  id="first-name"
                  v-model="firstName"
                  placeholder="First Name"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label-cols-md="12"
            >
              <label
                for="last-name"
              >
                Last Name <span class="text-danger">*</span>
              </label>
              <validation-provider
                #default="{ errors }"
                name="Last Name"
                rules="required"
              >
                <b-form-input
                  id="last-name"
                  v-model="lastName"
                  placeholder="Last Name"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label-cols-md="12"
            >
              <label
                for="email"
              >
                Email
              </label>
              <b-form-input
                id="email"
                v-model="email"
                placeholder="Email"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label-cols-md="12"
            >
              <label
                for="phone"
              >
                Phone Number <span class="text-danger">*</span>
              </label>
              <validation-provider
                #default="{ errors }"
                name="Phone Number"
                rules="required"
              >
                <b-input-group>
                  <b-form-input
                    id="phone"
                    v-model="phone"
                    placeholder="Phone Number"
                    :state="errors.length > 0 ? false:null"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="container my-2">
          <h3>Address</h3>
          <b-col cols="12">
            <b-form-group
              label-cols-md="12"
            >
              <label
                for="address-name"
              >
                Name <span class="text-danger">*</span>
              </label>
              <validation-provider
                #default="{ errors }"
                name="Address Name"
                rules="required"
              >
                <b-input-group>
                  <b-form-input
                    id="address-name"
                    v-model="addressName"
                    placeholder="Name"
                    :state="errors.length > 0 ? false:null"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label-cols-md="12"
            >
              <label
                for="city"
              >
                City <span class="text-danger">*</span>
              </label>
              <validation-provider
                #default="{ errors }"
                name="City"
                rules="required"
              >
                <v-select
                  id="city"
                  v-model="city"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="cities"
                  label="name"
                  :clearable="false"
                  class="w-100"
                  placeholder="Select City"
                  :reduce="ci => ci.id"
                  :state="errors.length > 0 ? false:null"
                  @input="handleCityChange"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label-cols-md="12"
            >
              <label
                for="area"
              >
                Area <span class="text-danger">*</span>
              </label>
              <validation-provider
                #default="{ errors }"
                name="Area"
                rules="required"
              >
                <v-select
                  id="area"
                  v-model="area"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="areas"
                  label="name"
                  :clearable="false"
                  class="w-100"
                  placeholder="Select Area"
                  :reduce="ar => ar.id"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label-cols-md="12"
            >
              <label
                for="block"
              >
                Block <span class="text-danger">*</span>
              </label>
              <validation-provider
                #default="{ errors }"
                name="Block"
                rules="required"
              >
                <b-form-input
                  id="block"
                  v-model="block"
                  placeholder="Block"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label-cols-md="12"
            >
              <label
                for="avenue"
              >
                Avenue
              </label>
              <b-form-input
                id="avenue"
                v-model="avenue"
                placeholder="Avenue"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label-cols-md="12"
            >
              <label
                for="street"
              >
                Street <span class="text-danger">*</span>
              </label>
              <validation-provider
                #default="{ errors }"
                name="Street"
                rules="required"
              >
                <b-input-group>
                  <b-form-input
                    id="street"
                    v-model="street"
                    placeholder="Street"
                    :state="errors.length > 0 ? false:null"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label-cols-md="12"
            >
              <label
                for="building"
              >
                Building/House <span class="text-danger">*</span>
              </label>
              <validation-provider
                #default="{ errors }"
                name="Building/House"
                rules="required"
              >
                <b-input-group>
                  <b-form-input
                    id="building"
                    v-model="building"
                    placeholder="Building/House"
                    :state="errors.length > 0 ? false:null"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label-cols-md="12"
            >
              <label
                for="floor"
              >
                Floor
              </label>
              <b-form-input
                id="floor"
                v-model="floor"
                placeholder="Floor"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label-cols-md="12"
            >
              <label
                for="apartment"
              >
                Apartment
              </label>
              <b-form-input
                id="apartment"
                v-model="apartment"
                placeholder="Apartment"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label-cols-md="12"
            >
              <label
                for="landmark"
              >
                Landmark
              </label>
              <b-form-input
                id="landmark"
                v-model="landmark"
                placeholder="Landmark"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label-cols-md="12"
            >
              <label
                for="details"
              >
                Details
              </label>
              <b-form-input
                id="details"
                v-model="details"
                placeholder="Details"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <hr/>
        <h3>Special Discount</h3>
        <b-row class="container">
          <b-col cols="12">
            <b-form-group
              label-cols-md="12"
            >
              <label
                for="discount"
              >
                Discount
              </label>
              <validation-provider
                #default="{ errors }"
                name="Discount"
                rules="between:0,100"
              >
                <b-input-group>
                  <b-form-input
                    id="discount"
                    v-model="discount"
                    placeholder="Enter Percentage"
                    :state="errors.length > 0 ? false:null"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <div class="text-danger">
              {{ error }}
            </div>
          </b-col>
          <!-- submit -->
          <b-col md="12">
            <b-button
              type="submit"
              variant="success"
              class="mr-1 w-100 my-2"
              :disabled="submitButtonDisabled"
              @click.prevent="handleSaveAndCreateNewOrder"
            >
              {{ submitButtonDisabled ? 'Loading...' : 'Save And Create New Order' }}
            </b-button>
          </b-col>
          <b-col md="12">
            <b-button
              type="submit"
              variant="primary"
              class="mr-1 w-100 my-2"
              :disabled="submitButtonDisabled"
              @click.prevent="handleSaveOnly"
            >
              {{ submitButtonDisabled ? 'Loading...' : 'Save Only' }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { onMounted, ref } from '@vue/composition-api'
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BInputGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required,
  between,
} from '@validations'
import axios from 'axios'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BForm,
    BButton,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    customer: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      required: true,
    },
  },
  setup(props, context) {
    const toast = useToast()
    const customerRules = ref(null)
    const cities = ref([])
    const areas = ref([])

    const firstName = ref('')
    const lastName = ref('')
    const email = ref('')
    const addressName = ref('Home')
    const phone = ref('')
    const city = ref('')
    const area = ref('')
    const block = ref('')
    const avenue = ref('')
    const street = ref('')
    const building = ref('')
    const floor = ref('')
    const apartment = ref('')
    const landmark = ref('')
    const details = ref('')
    const discount = ref('')
    const error = ref('')
    const createNewOrder = ref(false)
    const submitButtonDisabled = ref(false)

    const getCities = () => {
      axios.get(`${process.env.VUE_APP_BASE_API_URL}/countries/1/cities`).then(res => {
        cities.value = res.data.data.items
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching cities list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    }

    onMounted(() => {
      getCities()
    })

    const handleCityChange = () => {
      area.value = ''
      axios.get(`${process.env.VUE_APP_BASE_API_URL}/countries/1/cities/${city.value}`).then(res => {
        areas.value = res.data.data.items
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching areas list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    }

    const handleSaveAndCreateNewOrder = () => {
      createNewOrder.value = true
      // eslint-disable-next-line no-use-before-define
      handleSubmitCustomer()
    }

    const handleSaveOnly = () => {
      createNewOrder.value = false
      // eslint-disable-next-line no-use-before-define
      handleSubmitCustomer()
    }

    const handleSubmitCustomer = () => {
      customerRules.value.validate().then(success => {
        if (success) {
          submitButtonDisabled.value = true

          const data = {
            first_name: firstName.value,
            last_name: lastName.value,
            email: email.value,
            phone: phone.value,
            country_id: 1,
            order_discount: discount.value,
            address_name: addressName.value,
            area_id: area.value,
            avenue: avenue.value,
            apartment_number: apartment.value,
            floor_number: floor.value,
            landmark: landmark.value,
            primary: 1,
            block: block.value,
            street: street.value,
            building: building.value,
            details: details.value,
            location: '',
          }

          axios.post(`${process.env.VUE_APP_BASE_API_URL}/adduser-pos`, data, {
            headers: {
              Authorization: localStorage.getItem('token'),
            },
          }).then(res => {
            submitButtonDisabled.value = false

            toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: 'CheckIcon',
                variant: 'success',
              },
            },
            {
              position: 'top-center',
            })

            if (createNewOrder.value) {
              context.root.$router.push(`/orders/create/${res.data.data.user.id}`)
            } else {
              context.root.$router.go()
            }
          }).catch(err => {
            error.value = err.response.data.message
            submitButtonDisabled.value = false
          })
        }
      })
    }

    return {
      customerRules,
      areas,
      cities,
      firstName,
      lastName,
      email,
      phone,
      addressName,
      area,
      block,
      street,
      building,
      floor,
      apartment,
      city,
      avenue,
      landmark,
      details,
      discount,
      handleSubmitCustomer,
      handleSaveAndCreateNewOrder,
      handleSaveOnly,
      required,
      between,
      handleCityChange,
      error,
      submitButtonDisabled,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
