<template>
  <div class="container my-2">
    <validation-observer ref="pricelistRules">
      <b-form>
        <b-row class="container my-2">
<!--          <h3>Pricelist</h3>-->
          <b-col
            cols="9"
            xl="8"
            lg="8"
            md="8"
            sm="8"
            class="d-flex justify-content-end px-3"
          >
            <div
              class="d-flex flex-column justify-content-center align-items-center text-center border rounded px-4"
            >
              <img
                :src="imgUrl ? imgUrl : require('@/assets/images/addTag.png')"
                width="50"
                height="50"
                class="my-2 cursor-pointer"
                @click="selectImage"
              >
              <input
                id="pricelist-img-input"
                type="file"
                class="hidden"
                accept="image/*"
                @change="handleChangeImage"
              />
            </div>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label-cols-md="12"
            >
              <label
                for="pricelist-name-english"
              >
                Name (English)<span class="text-danger">*</span>
              </label>
              <validation-provider
                #default="{ errors }"
                name="Name (English)"
                rules="required"
              >
                <b-input-group>
                  <b-form-input
                    id="pricelist-name-english"
                    v-model="nameEn"
                    placeholder="Name (English)"
                    :state="errors.length > 0 ? false:null"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label-cols-md="12"
            >
              <label
                for="pricelist-name-arabic"
              >
                Name (Arabic)<span class="text-danger">*</span>
              </label>
              <validation-provider
                #default="{ errors }"
                name="Name (Arabic)"
                rules="required"
              >
                <b-input-group>
                  <b-form-input
                    id="pricelist-name-arabic"
                    v-model="nameAr"
                    placeholder="Name (Arabic)"
                    :state="errors.length > 0 ? false:null"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
<!--          <b-col cols="12">-->
<!--            <b-form-group-->
<!--              label-cols-md="12"-->
<!--            >-->
<!--              <label-->
<!--                for="parent"-->
<!--              >-->
<!--                Parent-->
<!--              </label>-->
<!--              <v-select-->
<!--                id="parent"-->
<!--                v-model="parentId"-->
<!--                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"-->
<!--                :options="parents"-->
<!--                label="name"-->
<!--                :clearable="true"-->
<!--                class="w-100"-->
<!--                placeholder="Select Parent"-->
<!--                :reduce="ci => ci.id"-->
<!--              />-->
<!--            </b-form-group>-->
<!--          </b-col>-->
          <b-col
            v-if="!parentId"
            cols="12"
          >
            <b-form-group
              label-cols-md="12"
            >
              <label
                for="price"
              >
                Price <span class="text-danger">*</span>
              </label>
              <validation-provider
                #default="{ errors }"
                name="Price"
                rules="required"
              >
                <b-input-group>
                  <b-form-input
                    id="price"
                    v-model="price"
                    placeholder="Price"
                    :state="errors.length > 0 ? false:null"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            v-if="parentId"
            cols="12"
          >
            <b-form-group
              label-cols-md="12"
            >
              <label
                for="iron-price"
              >
                Iron Price <span class="text-danger">*</span>
              </label>
              <validation-provider
                #default="{ errors }"
                name="Iron Price"
                rules="required"
              >
                <b-form-input
                  id="iron-price"
                  v-model="ironPrice"
                  placeholder="Iron Price"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            v-if="parentId"
            cols="12"
          >
            <b-form-group
              label-cols-md="12"
            >
              <label
                for="wash-iron-price"
              >
                Wash & Iron Price <span class="text-danger">*</span>
              </label>
              <validation-provider
                #default="{ errors }"
                name="Wash & Iron Price"
                rules="required"
              >
                <b-form-input
                  id="wash-iron-price"
                  v-model="washIronPrice"
                  placeholder="Wash Iron Price"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            v-if="parentId"
            cols="12"
          >
            <b-form-group
              label-cols-md="12"
            >
              <label
                for="dry-clean-price"
              >
                Dry Clean Price <span class="text-danger">*</span>
              </label>
              <validation-provider
                #default="{ errors }"
                name="Dry Clean Price"
                rules="required"
              >
                <b-form-input
                  id="dry-clean-price"
                  v-model="dryCleanPrice"
                  placeholder="Dry Clean Price"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
<!--          <b-col cols="12">-->
<!--            <b-form-group-->
<!--              label-cols-md="12"-->
<!--            >-->
<!--              <label-->
<!--                for="active"-->
<!--              >-->
<!--                Is Active?-->
<!--              </label>-->
<!--              <b-form-checkbox-->
<!--                id="active"-->
<!--                v-model="active"-->
<!--              />-->
<!--            </b-form-group>-->
<!--          </b-col>-->
        </b-row>
        <b-row class="container">
          <b-col md="12">
            <div class="text-danger">
              {{ error }}
            </div>
          </b-col>
          <!-- submit -->
          <b-col md="12">
            <b-button
              type="submit"
              variant="primary"
              class="mr-1 w-100 my-2"
              :disabled="submitButtonDisabled"
              @click.prevent="handleSubmit"
            >
              Save
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { onMounted, ref } from '@vue/composition-api'
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BInputGroup, BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required,
  between,
} from '@validations'
import axios from 'axios'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  emits: ['refreshPriceList'],
  setup(props, context) {
    const toast = useToast()
    const pricelistRules = ref(null)
    const parents = ref([])

    const nameEn = ref('')
    const nameAr = ref('')
    const price = ref(0)
    const parentId = ref(245)
    const img = ref(null)
    const imgUrl = ref('')
    const ironPrice = ref(null)
    const washIronPrice = ref(null)
    const dryCleanPrice = ref(null)
    const active = ref(true)
    const error = ref('')
    const submitButtonDisabled = ref(false)

    const getParents = () => {
      axios.get(`${process.env.VUE_APP_BASE_API_URL}/pos/pricelist`, {
        headers: {
          Authorization: localStorage.getItem('token'),
        },
      }).then(res => {
        parents.value = res.data.data.items
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching price list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    }

    onMounted(() => {
      getParents()
    })

    const selectImage = () => {
      document.getElementById('pricelist-img-input').click()
    }

    const createBase64Image = fileObject => {
      const reader = new FileReader()

      reader.onload = e => {
        img.value = e.target.result
      }
      reader.readAsDataURL(fileObject)
    }

    const handleChangeImage = e => {
      const file = e.target.files[0]
      // eslint-disable-next-line prefer-destructuring

      imgUrl.value = URL.createObjectURL(file)

      createBase64Image(file)
    }

    const handleSubmit = () => {
      pricelistRules.value.validate().then(success => {
        if (success) {
          submitButtonDisabled.value = true

          const data = {
            services_id: 1,
            name_en: nameEn.value,
            name_ar: nameAr.value,
            image: img.value,
            active: active.value,
            parent_id: parentId.value,
            price: price.value,
            iron_price: ironPrice.value,
            wash_iron_price: washIronPrice.value,
            dry_clean_price: dryCleanPrice.value,
          }


          axios.post(`${process.env.VUE_APP_BASE_API_URL}/pos/pricelist`, data, {
            headers: {
              Authorization: localStorage.getItem('token'),
            },
          }).then(res => {
            submitButtonDisabled.value = false

            toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: 'CheckIcon',
                variant: 'success',
              },
            },
            {
              position: 'top-center',
            })

            if (res.data.data.item.active) {
              context.emit('refreshPriceList', parentId.value, res.data.data.item)
            }
            context.root.$emit('bv::toggle::collapse', 'add-item-sidebar')
          }).catch(err => {
            error.value = err.response.data.message
            submitButtonDisabled.value = false
          })
        }
      })
    }

    return {
      pricelistRules,
      parents,
      nameEn,
      nameAr,
      price,
      parentId,
      img,
      imgUrl,
      ironPrice,
      washIronPrice,
      dryCleanPrice,
      selectImage,
      handleChangeImage,
      active,
      handleSubmit,
      required,
      between,
      submitButtonDisabled,
      error,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
